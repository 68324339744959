import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import { getShorthandPeriod } from '../helpers/helpers';

import NewSchedulerCalendar from './new-scheduler-calendar';
import InstallersListFilter from './installers-list-filter';
import {
  getNewSchedulerData,
  getNewSchedulerInitialData,
  getProjectDataById,
  getRescheduleReasonOptions,
  updateProjectScheduleInfo,
} from './service/new-scheduler.service';
import ProjectEditDialog from './project-edit-dialog';
import {
  ProjectScheduleVaidationSchema,
  getProjectFormikInitialValues,
} from './project-formik';
import BlockInstallerDialog from './block-installer-dialog';
import ScheduleDropDialog from './schedule-drop-dialog';

const NewScheduler = ({
  reportData,
  calendarViewAPICall,
  setCalendarViewAPICall,
  calendarFilterData,
  datePeriodForTechnicianView,
}) => {
  const dispatch = useDispatch();
  const {
    projectData,
    viewChangeFlag,
    refreshFlag,
    isWeekendsVisible,
    isSelectAllInstallers,
    installersList,
  } = useSelector(state => state.newScheduler);
  let { eventsQueryParams } = useSelector(state => state.newScheduler);
  const toast = useRef(null);

  const { technicians } = useSelector(state => state.technicians);
  const selectedAllInstallersList = Array.isArray(technicians)
    ? technicians?.map(item => {
        return {
          name: `${item?.first_name} ${item?.last_name}`,
          id: item?.installer_id,
          installerUserId: item?.user_id,
        };
      })
    : [];

  const fetchInitiationData = async () => {
    dispatch(setNewSchedulerComponent({ isLoading: true }));
    try {
      const defaultView = getShorthandPeriod(datePeriodForTechnicianView);
      const initializationData = await getNewSchedulerInitialData(defaultView);

      const { eventsList } = initializationData;
      const filteredEvents = Array.isArray(eventsList)
        ? eventsList?.filter(
            event =>
              (event?.schedule_date !== null &&
                event?.schedule_end_date !== null) ||
              (event?.time_off_start_date !== null &&
                event?.time_off_end_date !== null)
          )
        : [];

      dispatch(
        setNewSchedulerComponent({
          selectedAllInstallersList,
          // selectedInstallers: selectedAllInstallersList,
          eventsList: filteredEvents,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setNewSchedulerComponent({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
  }, [refreshFlag]);

  const fetchDataOnCalendarViewChange = async () => {
    dispatch(setNewSchedulerComponent({ isLoading: true }));
    try {
      const additionalParams = {};
      if (calendarFilterData) {
        additionalParams.projectTypeIds =
          calendarFilterData?.project_type_id || null;
        additionalParams.projectCategoryId =
          calendarFilterData?.project_category_id || null;
        additionalParams.projectStoreId =
          calendarFilterData?.project_store_id || null;
        if (calendarFilterData?.isJobCoverage)
          additionalParams.isJobCoverage = calendarFilterData?.isJobCoverage;
      }
      const defaultView = getShorthandPeriod(datePeriodForTechnicianView);
      dispatch(
        setNewSchedulerComponent({
          eventsQueryParams: {
            date: new Date().toISOString(),
            period: defaultView,
          },
        })
      );
      const newEventsList = await getNewSchedulerData(
        eventsQueryParams,
        additionalParams
      );
      const filteredEvents = newEventsList.filter(
        event =>
          (event?.schedule_date !== null &&
            event?.schedule_end_date !== null) ||
          (event?.time_off_start_date !== null &&
            event?.time_off_end_date !== null)
      );

      dispatch(
        setNewSchedulerComponent({
          eventsList: filteredEvents,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setNewSchedulerComponent({ isLoading: false }));
    }
  };

  useEffect(() => {
    if (!calendarViewAPICall) {
      fetchDataOnCalendarViewChange();
    }
    setCalendarViewAPICall(false);
  }, [viewChangeFlag]);

  useEffect(() => {
    if (calendarFilterData) {
      fetchDataOnCalendarViewChange();
    }
  }, [calendarFilterData]);

  const projectDetailsFormik = useFormik({
    initialValues: getProjectFormikInitialValues(projectData),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      let updateResponse;
      try {
        updateResponse = await updateProjectScheduleInfo(
          projectData.project_id,
          {
            reschedule_reason_id: values?.reschedule_reason?.reason_id,
            rts_follow_up_date: values?.rts_follow_up_date,
            date_scheduled_start: values?.date_scheduled_start,
            date_scheduled_end: values?.date_scheduled_end,
            project_type_id: values?.project_type_id,
          }
        );
        if (updateResponse.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Project Details updated successfully',
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            life: 2000,
          });
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(
          setNewSchedulerComponent({
            isProjectEditDialogOpen: false,
          })
        );
      }
    },
    validationSchema: ProjectScheduleVaidationSchema,
    enableReinitialize: true,
  });

  const handleCalendarEventClick = async event => {
    const { projectId, sourceSystemId, rescheduleReason, isTimeOffEvent } =
      event?.event?.extendedProps;
    if (!isTimeOffEvent) {
      dispatch(
        setNewSchedulerComponent({
          isProjectEditDialogOpen: true,
        })
      );
      const projectData = projectId && (await getProjectDataById(projectId));
      const rescheduleReasons =
        sourceSystemId && (await getRescheduleReasonOptions(sourceSystemId));

      dispatch(
        setNewSchedulerComponent({
          projectData,
          rescheduleReasons,
        })
      );
      projectDetailsFormik?.handleReset();
    }
  };

  const handleWeekendToggle = () => {
    dispatch(
      setNewSchedulerComponent({
        isWeekendsVisible: !isWeekendsVisible,
      })
    );
  };
  const handleAllInstallerToggle = event => {
    const isSelectAll = event?.target?.checked;
    const selectedInstallersList = isSelectAll ? selectedAllInstallersList : [];
    dispatch(
      setNewSchedulerComponent({
        selectedInstallers: selectedInstallersList,
        isSelectAllInstallers: !isSelectAllInstallers,
      })
    );
  };

  return (
    <>
      <div className="flex flex-column w-full">
        <InstallersListFilter />
        <div className="flex flex-row w-full gap-4 mb-2">
          <div className="flex mt-2">
            <Checkbox
              inputId="weekends"
              name="weekends"
              value="weekends"
              onChange={handleWeekendToggle}
              checked={isWeekendsVisible}
            />
            <label htmlFor="weekends" className="ml-2">
              Show Weekends
            </label>
          </div>
          <div className="flex  mt-2">
            <Checkbox
              inputId="isSelectAllInstallers"
              name="isSelectAllInstallers"
              value="isSelectAllInstallers"
              onChange={handleAllInstallerToggle}
              checked={isSelectAllInstallers}
            />
            <label htmlFor="isSelectAllInstallers" className="ml-2">
              Select All
            </label>
          </div>
        </div>
        <div className="flex flex-row w-full">
          <NewSchedulerCalendar
            onEventClick={handleCalendarEventClick}
            reportData={reportData}
            datePeriodForTechnicianView={datePeriodForTechnicianView}
          />
        </div>
        <ProjectEditDialog projectDetailsFormik={projectDetailsFormik} />
        <BlockInstallerDialog />
        <ScheduleDropDialog />
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default NewScheduler;
