// **** React Imports ****
import React from 'react';

// **** External Utilities ****
import {
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  useMediaQuery,
} from '@material-ui/core';

// **** Styles/Images/Icons ****
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import PageMeta from '../../PageMeta/PageMeta';

import { useStyles } from './PageHeader.styles';

const PageHeader = ({ pageTitle, breadCrumbArray }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <Grid container item direction="column" spacing={1}>
      <Grid item className="flex align-items-center">
        <PageMeta />
        <Typography variant="h2">{pageTitle}</Typography>
      </Grid>
      <Grid item>
        <Breadcrumbs
          separator={
            <FiberManualRecordIcon
              fontSize="small"
              className={classes.breadcrumb}
            />
          }
          aria-label="breadcrumb"
        >
          {breadCrumbArray?.map((breadcrumb, index) => {
            return ((index === 0 || index === breadCrumbArray.length - 1) &&
              matches) ||
              !matches ? (
              breadcrumb?.text !== undefined ? (
                <Link
                  to={breadcrumb.link || '#'}
                  key={index}
                  underline="none"
                  color={
                    index === breadCrumbArray.length - 1
                      ? 'textSecondary'
                      : 'textPrimary'
                  }
                >
                  {breadcrumb.text}
                </Link>
              ) : (
                <>{breadcrumb.textWithoutLink}</>
              )
            ) : (
              index !== 0 &&
                index !== breadCrumbArray.length - 1 &&
                matches && (
                  <Link
                    to={breadcrumb.link || '#'}
                    key={index}
                    underline="none"
                  >
                    <Grid
                      container
                      direction="row"
                      classes={{ root: classes.breadcrumbIconWrapper }}
                    >
                      <Grid item>
                        <FiberManualRecordIcon
                          fontSize="small"
                          className={classes.breadcrumb}
                        />
                      </Grid>
                      <Grid item>
                        <FiberManualRecordIcon
                          fontSize="small"
                          className={classes.breadcrumb}
                        />
                      </Grid>
                      <Grid item>
                        <FiberManualRecordIcon
                          fontSize="small"
                          className={classes.breadcrumb}
                        />
                      </Grid>
                    </Grid>
                  </Link>
                )
            );
          })}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
