import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeDashboard: null,
  activeWidget: null,
  widgetDetails: {},
  tabs: null,
  clientId: '',
  sideDrawer: ""
};

const pageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: initialState,
  reducers: {
    setPageHeaderComponent(state, { payload }) {
      if (payload?.activeWidget?.widgetTemplate?.widget_template_id) {
        state.widgetDetails = {
          ...state.widgetDetails,
          [payload.activeWidget.widgetTemplate.widget_template_id]: {
            activeDashboard: payload.hasOwnProperty('activeDashboard')
              ? payload.activeDashboard
              : state.activeDashboard,
            activeWidget: payload.hasOwnProperty('activeWidget')
              ? payload.activeWidget
              : state.activeWidget,
          },
        };
      }
      state.activeDashboard = payload.hasOwnProperty('activeDashboard')
        ? payload.activeDashboard
        : state.activeDashboard;
      state.activeWidget = payload.hasOwnProperty('activeWidget')
        ? payload.activeWidget
        : state.activeWidget;
    },
    setTabs(state, { payload }) {
      return { ...state, tabs: payload };
    },
    setClientId(state, { payload }) {
      return { ...state, clientId: payload };
    },
    setSideDrawer(state, {payload}){
      return { ...state, sideDrawer: payload };
    }
  },
});

export const { setPageHeaderComponent, setTabs, setClientId, setSideDrawer } =
  pageHeaderSlice.actions;

export const pageHeaderReducer = pageHeaderSlice.reducer;
