import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../../services/api.service';
const initialState = {
  workrooms: [],
  status: false,
  error: null,
};
export const fetchWorkrooms = createAsyncThunk(
  'master/fetchWorkrooms',
  async (_, { getState, rejectWithValue }) => {
    const { workrooms } = getState().workrooms;
    if (workrooms && workrooms.length > 0) {
      return rejectWithValue('Workroom already fetched');
    } else {
      const response = await ApiService.get('/system/workroom/list');
      return response;
    }
  }
);

const workroomsSlice = createSlice({
  name: 'workrooms',
  initialState,
  reducers: {
    clearWorkrooms: (state) => {
      state.workrooms = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkrooms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkrooms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workrooms = action.payload;
      })
      .addCase(fetchWorkrooms.rejected, (state, action) => {
        if (action.payload === 'Workroom already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearWorkrooms } = workroomsSlice.actions;

export const workroomsReducer = workroomsSlice.reducer;
