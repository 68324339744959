import React, { useRef, useState } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useSelector, useDispatch } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import {
  checkPermission,
  doesKeyExistInArray,
  formatTimeHM,
  getDefaultSchedulerView,
  getLocalClientDetails,
  getUniqueArrayItemsByKey,
  selectedRapidSlot,
} from '../../../utils/Helpers';
import { SCHEDULER_CARD_VIEW_OPTION } from '../constants/constants';
import permissions from '../../../config/permissions';

import { eventColorGenerator } from './color-codes';

const mapEventsDataForCalendar = (items, selectedInstallers) => {
  const selectedInstallerIds = selectedInstallers.map(i => i?.id);
  const mappedEvents = items
    ?.map(i => {
      const isTimeOffEvent =
        i?.time_off_start_date &&
        i?.time_off_end_date &&
        i?.project_id === null &&
        (i?.status_id === 86 || i?.status_id === 88);
      return {
        isTimeOffEvent,
        start: isTimeOffEvent ? i?.time_off_start_date : i?.schedule_date,
        end: isTimeOffEvent ? i?.time_off_end_date : i.schedule_end_date,
        installerName: i?.installer_name,
        installerId: i?.installer_id,
        color: isTimeOffEvent ? '#ddd' : eventColorGenerator(i?.installer_id),
        textColor: isTimeOffEvent ? '#000' : undefined,
        projectId: i?.project_id ? i?.project_id : null,
        sourceSystemId: i?.source_system_id ? i?.source_system_id : null,
        rescheduleReason: i?.reschedule_reason ? i?.reschedule_reason : null,
        clientName: i?.client_name,
        projectType: i?.project_type,
        category: i?.category,
        projectStatus: i?.project_status,
        storeNumber: i?.store_number ? i?.store_number : null,
        statusId: i?.status_id,
        timeOffNote: i?.time_off_note,
        timeOffRequestId: i?.user_time_off_request_id,
        projectNumber: i?.project_number ? i?.project_number : null,
        projectDesc: i?.project_desc,
        totalSale: i?.total_sale_amount ? i?.total_sale_amount : '',
        totalRevenue: i?.total_revenue ? i?.total_revenue : '',
        address: i?.address,
        mobilePhone: i?.mobile_phone,
        time:
          i?.schedule_date && i?.schedule_end_date
            ? formatTimeHM(i?.schedule_date) +
              ' - ' +
              formatTimeHM(i?.schedule_end_date)
            : '',
      };
    })
    .filter(
      i =>
        i?.start !== null &&
        i?.end !== null &&
        selectedInstallerIds?.includes(i?.installerId)
    );
  const timeOffEvents = mappedEvents?.filter(i => !!i?.timeOffRequestId);
  const nonTimeOffEvents = mappedEvents?.filter(i => !i?.timeOffRequestId);
  return [
    ...nonTimeOffEvents,
    ...getUniqueArrayItemsByKey(timeOffEvents, 'timeOffRequestId'),
  ];
};

const getPeriod = type => {
  let period;
  switch (type) {
    case 'day':
      period = 'D';
      break;
    case 'week':
      period = 'W';
      break;
    case 'month':
      period = 'M';
      break;
  }
  return period;
};

const NewSchedulerCalendar = ({
  onEventClick,
  reportData,
  datePeriodForTechnicianView,
}) => {
  const dispatch = useDispatch();
  const { eventsList, selectedInstallers, viewChangeFlag, isWeekendsVisible } =
    useSelector(state => state.newScheduler);

  const calendarRef = useRef(null);
  const [cardVisibility, setCardVisibility] = useState(
    getLocalClientDetails().scheduler_config?.calender_view?.card_visibility
      ? getLocalClientDetails().scheduler_config?.calender_view?.card_visibility
      : null
  );
  const RenderEventContent = eventInfo => {
    const {
      installerName,
      clientName,
      projectType,
      category,
      projectStatus,
      storeNumber,
      isTimeOffEvent,
      timeOffNote,
      time,
      projectNumber,
      projectDesc,
      totalSale,
      totalRevenue,
      address,
      mobilePhone,
    } = eventInfo?.event?.extendedProps;
    let eventClasses = 'event-wrapper w-full h-full flex flex-column ';
    if (!isTimeOffEvent) {
      eventClasses = `${eventClasses} cursor-pointer overflow-y-auto overflow-x-hidden`;
    }
    const randomTooltipId = Math.random().toString(36).slice(-5);

    return (
      <>
        <Tooltip target={`[data-tt-id="${randomTooltipId}"]`} mouseTrack>
          <div className="flex flex-column">
            <span className="font-bold">{installerName}</span>
            {isTimeOffEvent && timeOffNote && <span>{timeOffNote}</span>}
            {!isTimeOffEvent && (
              <span> Description :{projectDesc && `${projectDesc}`}</span>
            )}

            {!isTimeOffEvent && <span>{`Time : ${time}`}</span>}
            {!isTimeOffEvent && storeNumber && (
              <span>{`Store # ${storeNumber}`}</span>
            )}
            {!isTimeOffEvent && projectType && (
              <span>{`Type : ${projectType}`}</span>
            )}
            {!isTimeOffEvent && category && (
              <span>{`Category : ${category}`}</span>
            )}
            {!isTimeOffEvent && projectStatus && (
              <span>{`Status : ${projectStatus}`}</span>
            )}
            {!isTimeOffEvent &&
              checkPermission(
                permissions?.projectItems?.viewProjectLevelCostField
              ) && <span>{`Total Sale $ : ${totalSale}`}</span>}
            {!isTimeOffEvent &&
              checkPermission(
                permissions?.projectItems?.viewProjectLevelCostField
              ) && <span>{`Total Revenue $ : ${totalRevenue}`}</span>}
            {!isTimeOffEvent && projectNumber && (
              <span>{`Project No : ${projectNumber}`}</span>
            )}

            {!isTimeOffEvent && clientName && (
              <span>{`Client Name : ${clientName}`}</span>
            )}
            {!isTimeOffEvent && (
              <span>Address : {address && `${address}`}</span>
            )}
            {!isTimeOffEvent && (
              <span>Phone : {mobilePhone && `${mobilePhone}`}</span>
            )}
          </div>
        </Tooltip>
        <div className={eventClasses} data-tt-id={randomTooltipId}>
          <span className="font-bold text-sm">{installerName}</span>
          {isTimeOffEvent && timeOffNote && <span>{timeOffNote}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.description
            ) && <span>Description :{projectDesc && `${projectDesc}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.time
            ) && <span>{`Time : ${time}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.store
            ) &&
            storeNumber && <span>{`Store # ${storeNumber}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.project_type
            ) &&
            projectType && <span>{`Type : ${projectType}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.category
            ) &&
            category && <span>{`Category : ${category}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.status
            ) &&
            projectStatus && <span>{`Status : ${projectStatus}`}</span>}

          {!isTimeOffEvent &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.total_sale
            ) && <span>{`Total Sale $ : ${totalSale}`}</span>}
          {!isTimeOffEvent &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.total_revenue
            ) && <span>{`Total Revenue $ : ${totalRevenue}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.project_number
            ) &&
            projectNumber && <span>{`Project No : ${projectNumber}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.client_name
            ) &&
            clientName && <span>{`Client Name : ${clientName}`}</span>}

          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.address
            ) && <span>Address:{address && `${address}`}</span>}
          {!isTimeOffEvent &&
            doesKeyExistInArray(
              cardVisibility,
              SCHEDULER_CARD_VIEW_OPTION[0].type.phone
            ) && <span>Phone : {mobilePhone && `${mobilePhone}`}</span>}
        </div>
      </>
    );
  };

  const handleViewChange = view => {
    let params;
    let calendarApi = !!calendarRef && calendarRef?.current?.getApi();
    switch (view) {
      case 'prev':
        calendarApi?.prev();
        params = {
          date: calendarApi?.getDate().toISOString(),
          period: getPeriod(calendarApi?.view?.type),
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'next':
        calendarApi?.next();
        params = {
          date: calendarApi?.getDate().toISOString(),
          period: getPeriod(calendarApi?.view?.type),
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'day':
        calendarApi?.changeView('day');
        params = {
          date: calendarApi?.getDate().toISOString(),
          period: 'D',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'week':
        calendarApi?.changeView('week');
        params = {
          date: calendarApi?.getDate().toISOString(),
          period: 'W',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
      case 'month':
        calendarApi?.changeView('month');
        params = {
          date: calendarApi?.getDate().toISOString(),
          period: 'M',
        };
        dispatch(
          setNewSchedulerComponent({
            eventsQueryParams: params,
            viewChangeFlag: !viewChangeFlag,
          })
        );
        break;
    }
  };

  const handleSelect = selectInfo => {
    const { minutesDifference, startSlotTime } = selectedRapidSlot(selectInfo);
    dispatch(
      setNewSchedulerComponent({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: selectInfo.start,
          end: minutesDifference > 0 ? selectInfo.end : startSlotTime,
        },
      })
    );
  };

  const handleNewEvent = () => {
    dispatch(
      setNewSchedulerComponent({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: new Date(),
          end: new Date(),
        },
      })
    );
  };

  const handleDrop = drop => {
    const dropDialogData = reportData?.find(
      data =>
        data?.project_id ===
        parseInt(drop?.draggedEl?.attributes['data-event']?.value)
    );
    const endDateTime = new Date(drop?.dateStr).getTime() + 3600000;
    const dropInfoObject = {
      start: new Date(drop?.dateStr).toISOString(),
      end: new Date(endDateTime).toISOString(),
    };
    dispatch(
      setNewSchedulerComponent({
        dropDialogData,
        dropInfo: dropInfoObject,
        isDropDialogOpen: true,
      })
    );
  };

  return (
    <div className="flex flex-column w-full calendar-container">
      <FullCalendar
        timeZone="local"
        ref={calendarRef}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        selectable={true}
        plugins={[timeGridPlugin, interactionPlugin]}
        views={{
          month: {
            type: 'timeGrid',
            duration: { month: 1 },
            buttonText: 'Month',
            dayHeaderFormat: { day: 'numeric', weekday: 'short' },
          },
          week: {
            type: 'timeGrid',
            duration: { weeks: 1 },
            buttonText: 'Week',
          },
          day: {
            type: 'timeGrid',
            duration: { days: 1 },
            buttonText: 'Day',
          },
        }}
        customButtons={{
          prevButton: {
            icon: 'fc-icon-chevron-left',
            click: () => handleViewChange('prev'),
          },
          nextButton: {
            icon: 'fc-icon-chevron-right',
            click: () => handleViewChange('next'),
          },
          newEvent: {
            text: 'New Event',
            click: () => handleNewEvent(),
          },
          day: {
            text: 'Day',
            click: () => handleViewChange('day'),
          },
          week: {
            text: 'Week',
            click: () => handleViewChange('week'),
          },
          month: {
            text: 'Month',
            click: () => handleViewChange('month'),
          },
        }}
        headerToolbar={{
          left: 'newEvent',
          center: 'prevButton title nextButton',
          right: 'day,week,month',
        }}
        initialView={datePeriodForTechnicianView}
        height={700}
        initialEvents={[]}
        events={mapEventsDataForCalendar(eventsList, selectedInstallers)}
        eventContent={RenderEventContent}
        eventClick={onEventClick}
        scrollTime="08:00:00" // default is '06:00:00'
        allDaySlot={false}
        slotMinTime="08:00:00"
        slotMaxTime="20:01:00"
        select={handleSelect}
        droppable={true}
        drop={handleDrop}
        weekends={isWeekendsVisible}
        firstDay={1}
      />
    </div>
  );
};

export default NewSchedulerCalendar;
