import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Tag } from 'primereact/tag';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import apiService from '../../../services/api.service';
import {
  suggestCategory,
  suggestStore,
  suggestDistrict,
  suggestProjectStatus,
  suggestProjectType,
  suggestSourceStatus,
  suggestWorkRoom,
  selectedLabel,
  suggestCustomerOptions,
  createPayloadFromFormikValues,
  isAtLeastOneFieldSelected,
} from '../../Admin/NewConfiguration/SchedularReportConfig/components/AddEditReport.model';
import { getLoginUserId } from '../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { SchedulerCustomFilters } from '../../../constants';
import { checkOtherFieldsHaveValues } from '../helpers/helpers';

import AddCustomFilter from './AddCustomFilter';
import SaveCustomFilter from './SaveCustomFilter';

const CustomFilter = ({
  defaultFilters,
  handleDefaultFilters,
  setSelectedFilters,
  fetchDefaultFilterList,
  setCalendarFilterData,
}) => {
  const [selectedDefaultFilterId, setSelectedDefaultFilterId] = useState(null);
  const [customFilterDialogVisible, setCustomFilterDialogVisible] =
    useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = useState([]);
  const [projectStatusOptions, setProjectStatusOptions] = useState([]);
  const [sourceStatusOptions, setSourceStatusOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workRoomOptions, setWorkroomOptions] = useState([]);
  const [addCustomFilterVisible, setAddCustomFilterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdownError, setDropdownError] = useState(false);
  const [deafultFilterId, setDefaultFilterId] = useState(null);
  const toast = useRef();

  //Redux
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { districts } = useSelector(state => state.districts);
  const { workrooms } = useSelector(state => state.workrooms);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);

  const setMasterScheduler = () => {
    setStoreOptions(projectStores);
    setCategoryOptions(categories);
    setProjectTypeOptions(types);
    setProjectStatusOptions(projectStatusType);
    setSourceStatusOptions(sourceStatusType);
    setDistrictOptions(districts);
    setWorkroomOptions(workrooms);
  };

  useEffect(() => {
    setMasterScheduler();
  }, []);

  //Save the Filter
  const saveUserFilter = async value => {
    try {
      setLoading(true);
      const userId = getLoginUserId();
      const response = await apiService.post(
        apiService.formatUrl(
          URL_CONSTANTS.SCHEDULER_FILTERS.saveUserLevelFilter,
          userId
        ),
        value
      );
      if (response && response?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'User Filter save successfully.',
        });
        setLoading(false);
        setAddCustomFilterVisible(false);
        setCustomFilterDialogVisible(false);
        fetchDefaultFilterList();
        customFilterFormik.handleReset();
      } else {
        toast.current?.show({
          severity: 'error',
          summary: response?.data
            ? `${response?.data}`
            : 'Unable to add record.',
        });
        setLoading(false);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        detail: 'Unable to add record.',
      });
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (defaultFilters && defaultFilters?.length > 0) {
      const defaultFilter = defaultFilters?.find(
        filter => filter?.is_default === 1
      );

      if (defaultFilter) {
        setSelectedDefaultFilterId(defaultFilter.filter_id);
        handleDefaultFilters(defaultFilter);
        setDefaultFilterId(defaultFilter);
      }
    }
  }, [defaultFilters]);

  const handleDefaultFilterChange = value => {
    setSelectedDefaultFilterId(value);
    let selectedFilter = defaultFilters?.find(item => value === item.filter_id);
    if (value == SchedulerCustomFilters.CUSTOMTYPE) {
      setCustomFilterDialogVisible(true);
      setMasterScheduler();
      return;
    }
    if (value) {
      handleDefaultFilters(selectedFilter);
      return;
    }
    handleNoValueSelected();
  };
  const handleNoValueSelected = () => {
    handleDefaultFilters();
    setSelectedDefaultFilterId(null);
  };
  const fieldsToCheck = [
    'storeNumber',
    'projectCategoryId',
    'statusId',
    'imsStatus',
    'projectTypeId',
    'districtId',
    'workroomId',
  ];
  const formik = useFormik({
    initialValues: {
      customerId: [],
      storeNumber: [],
      projectCategoryId: [],
      statusId: [],
      imsStatus: [],
      projectTypeId: [],
      districtId: [],
      workroomId: [],
    },
    enableReinitialize: true,
    onSubmit: values => {
      setSelectedFilters(values);
      setCustomFilterDialogVisible(false);
      customFilterFormik.handleReset();
    },
  });

  const customFilterFormikValidation = Yup.object().shape({
    filter_name: Yup.string().required('Required'),
  });
  const customFilterFormik = useFormik({
    initialValues: {
      filter_name: '',
      isActive: false,
    },
    validationSchema: customFilterFormikValidation,
    enableReinitialize: true,
    onSubmit: values => {
      const payload = createPayloadFromFormikValues(values);
      if (!isAtLeastOneFieldSelected(customFilterFormik)) {
        setDropdownError(true);
        return;
      } else {
        setDropdownError(false);
        saveUserFilter(payload);
      }
    },
  });
  const assignFormikValues = () => {
    customFilterFormik.setValues({
      ...customFilterFormik.values,
      store: formik.values.storeNumber,
      projectCategory: formik.values.projectCategoryId,
      status: formik.values.statusId,
      sourceStatus: formik.values.imsStatus,
      projectType: formik.values.projectTypeId,
      district: formik.values.districtId,
      workroom: formik.values.workroomId,
      customer: formik.values.customerId,
    });
  };
  const defaultFiltersTemplate = options => {
    return (
      <span className="flex align-items-center">
        <span>{options?.filter_name}</span>
        {options?.userFilterInfo ? (
          <span className="pl-2 text-xs">
            <Tag value="Custom"></Tag>
          </span>
        ) : (
          ''
        )}
        {options && options?.is_default ? (
          <span className="pl-2 text-xs">
            <Tag severity="success" value="Default"></Tag>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  };

  const suggestionsProps = {
    suggestCategory,
    suggestStore,
    suggestDistrict,
    suggestProjectStatus,
    suggestProjectType,
    suggestSourceStatus,
    suggestWorkRoom,
    suggestCustomerOptions,
  };

  const optionsProps = {
    customerOptions,
    storeOptions,
    categoryOptions,
    projectTypeOptions,
    projectStatusOptions,
    sourceStatusOptions,
    districtOptions,
    workRoomOptions,
    setCustomerOptions,
    setStoreOptions,
    setCategoryOptions,
    setProjectTypeOptions,
    setProjectStatusOptions,
    setSourceStatusOptions,
    setDistrictOptions,
    setWorkroomOptions,
  };

  const shouldShowSaveButton = useMemo(() => {
    if (formik?.dirty) {
      return checkOtherFieldsHaveValues(formik?.values, fieldsToCheck);
    }
    return false;
  }, [formik.dirty]);

  return (
    <>
      <div className="col-12 flex py-0 mb-2 flex justify-content-end pr-0">
        <Toast ref={toast} />
        <div className="flex justify-content-evenly">
          {shouldShowSaveButton &&
            selectedDefaultFilterId == SchedulerCustomFilters.CUSTOMTYPE && (
              <PFButton
                onClick={() => {
                  setAddCustomFilterVisible(true);
                  assignFormikValues();
                }}
                className="mx-3 text-base"
                label="Save"
                severity
                text
              />
            )}
        </div>
        <div className="col-8 p-0">
          <span className="p-float-label w-full">
            <PFDropdown
              value={selectedDefaultFilterId}
              onChange={(name, value) => {
                handleDefaultFilterChange(value);
                setCalendarFilterData({
                  client_name: null,
                  project_number: null,
                  store_name: null,
                  project_type: null,
                  po_number: null,
                });
              }}
              options={defaultFilters}
              itemTemplate={defaultFiltersTemplate}
              optionLabel="filter_name"
              optionValue="filter_id"
              placeholder="Select a filter"
              name="default_filters"
              className="w-full"
              showClear={selectedDefaultFilterId ?? false}
            />
            <label htmlFor="default_filters">Filters</label>
          </span>
        </div>
      </div>
      {customFilterDialogVisible && (
        <AddCustomFilter
          customFilterDialogVisible={customFilterDialogVisible}
          setCustomFilterDialogVisible={setCustomFilterDialogVisible}
          formik={formik}
          suggestionsProps={suggestionsProps}
          optionsProps={optionsProps}
          selectedLabel={selectedLabel}
          customFilterFormik={customFilterFormik}
          loading={loading}
          dropdownError={dropdownError}
          setDropdownError={setDropdownError}
          assignFormikValues={assignFormikValues}
          handleNoValueSelected={handleNoValueSelected}
        />
      )}
      {addCustomFilterVisible && (
        <SaveCustomFilter
          addCustomFilterVisible={addCustomFilterVisible}
          setAddCustomFilterVisible={setAddCustomFilterVisible}
          customFilterFormik={customFilterFormik}
          loading={loading}
          dropdownError={dropdownError}
          setDropdownError={setDropdownError}
        />
      )}
    </>
  );
};
export default CustomFilter;
