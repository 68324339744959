import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../../services/api.service';
const initialState = {
  installerWorkType: [],
  status: false,
  error: null,
};
export const fetchInstallerWorkType = createAsyncThunk(
  'master/fetchInstallerWorkType',
  async (_, { getState, rejectWithValue }) => {
    const { installerWorkType } = getState().installerWorkType;
    if (installerWorkType && installerWorkType.length > 0) {
      return rejectWithValue('Installer work type already fetched');
    } else {
      const response = await ApiService.get('/system/installer-work-type');
      return response;
    }
  }
);
const installerWorkTypeSlice = createSlice({
  name: 'installerWorkType',
  initialState,
  reducers: {
    clearInstallerWorkType: (state) => {
      state.installerWorkType = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstallerWorkType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInstallerWorkType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.installerWorkType = action.payload;
      })
      .addCase(fetchInstallerWorkType.rejected, (state, action) => {
        if (action.payload === 'Installer work type already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});
export const { clearInstallerWorkType } = installerWorkTypeSlice.actions;

export const installerWorkTypeReducer = installerWorkTypeSlice.reducer;
