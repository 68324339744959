// **** External Utilities ****
import axios from 'axios';

import { URL_CONSTANTS } from '../../constants/urlConstants';

// Get Scheduling Dashboard Data
export const getCalendarData = async (
  params,
  setCalendarData,
  setCalendarLoading,
  setAlert,
  filterCalenderData,
  setInitialLoader = () => {}
) => {
  try {
    setCalendarLoading(true);
    let newQueryString = params
      ? Object.entries(params)
          .filter(([prop, value]) => Boolean(value) || value === 0)
          .map(([key, value], index) => `${key}=${value?.toString()}`)
          .join('&')
      : '';

    if (
      filterCalenderData &&
      Object.values(filterCalenderData).some(value => value.length)
    ) {
      let searchQueryWithFilters = Object.entries(filterCalenderData)
        .filter(([prop, value]) => value?.length)
        .map(([key, value], index) => `${key}=${value?.toString()}`)
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }
    newQueryString = newQueryString
      ? newQueryString.includes('isJobCoverage=true')
        ? newQueryString
        : newQueryString + '&isJobCoverage=true'
      : 'isJobCoverage=true';

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view?${newQueryString}`
    );
    setCalendarData(response?.data?.items);
    setInitialLoader(false);
  } catch (error) {
    setAlert('error', 'Failed to fetch scheduled jobs');
  } finally {
    setCalendarLoading(false);
    setInitialLoader(false);
  }
};

// Update schedule
export const updateInstallerSchedule = async (
  updateObj,
  setAlert,
  setCalendarLoading,
  setReloadList,
  setButtonDisabled = () => {},
  setIsOpen = () => {}
) => {
  try {
    setCalendarLoading(true);
    setButtonDisabled(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/update`,
      updateObj
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Job has been scheduled successfully');
    }
    return response.data;
  } catch (error) {
    setAlert('error', 'Failed to reschedule job');
  } finally {
    setCalendarLoading(false);
    setButtonDisabled(false);
    setIsOpen(false);
  }
};
