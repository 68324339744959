import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../../services/api.service';
const initialState = {
  technicians: [],
  status: false,
  error: null,
};
export const fetchTechnicians = createAsyncThunk(
  'master/fetchTechnicians',
  async (_, { getState, rejectWithValue }) => {
    const { technicians } = getState().technicians;
    if (technicians && technicians.length > 0) {
      return rejectWithValue('Technicians already fetched');
    } else {
      const response = await ApiService.get('/auth/user/report/get-installers');
      return response;
    }
  }
);
const techniciansSlice = createSlice({
  name: 'technicians',
  initialState,
  reducers: {
    clearTechnicians: (state) => {
      state.technicians = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTechnicians.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTechnicians.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.technicians = action.payload;
      })
      .addCase(fetchTechnicians.rejected, (state, action) => {
        if (action.payload === 'Technicians already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});
export const { clearTechnicians } = techniciansSlice.actions;

export const techniciansReducer = techniciansSlice.reducer;
