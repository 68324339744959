import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../../services/api.service';
const initialState = {
  categories: [],
  status: false,
  error: null,
};
export const fetchCategories = createAsyncThunk(
  'master/fetchCategories',
  async (_, { getState, rejectWithValue }) => {
    const { categories } = getState().categories;
    if (categories && categories.length > 0) {
      return rejectWithValue('categories already fetched');
    } else {
      const response = await ApiService.get('/projects/master/project-category');
      return response;
    }
  }
);
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    clearCategories: (state) => {
      state.categories = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        if (action.payload === 'categories already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearCategories } = categoriesSlice.actions;

export const categoriesReducer = categoriesSlice.reducer;
